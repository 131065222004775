.container {
  margin: 20px;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.listItem {
  margin-bottom: 10px;
  padding: 10px;
  margin-left:-20px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.span {
  font-weight: bold;
}

.listItem h3 {
  font-size: 18px;
  margin-bottom: 5px;
  color: #333;
}

.listItem p {
  margin: 0;
  color: #666;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .container {
    margin: 10px;
    padding: 5px;
  }

  .title {
    font-size: 20px;
  }

  .listItem {
    padding: 5px;
  }

  .listItem h3 {
    font-size: 16px;
  }
}
/* Media Queries */
@media screen and (max-width: 380px) {
  .container {
    margin: 10px;
    padding: 5px;
  }

  .title {
    font-size: 20px;
  }

  .listItem {
    padding: 5px;
  }

  .listItem h3 {
    font-size: 16px;
  }
}
