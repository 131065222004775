/* Container styles */
.about-payment {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  /* Heading styles */
  .about-payment h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  /* Payment option styles */
  .about-payment .payment-option {
    margin-bottom: 20px;
  }
  
  .about-payment .payment-option label {
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .about-payment .payment-option input[type="radio"] {
    margin-right: 10px;
  }
  
  /* Bank details styles */
  .about-payment .bank-details {
    margin-top: 20px;
  }
  
  .about-payment .bank-details h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .about-payment .bank-details p {
    margin-bottom: 10px;
  }
  
  /* Contact us styles */
  .about-payment .contact-us {
    margin-top: 20px;
  }
  
  .about-payment .contact-us h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .about-payment .contact-us p {
    margin-bottom: 10px;
  }
  
  .about-payment .contact-us a {
    color: #007bff;
    text-decoration: none;
  }
  
  .about-payment .contact-us a:hover {
    text-decoration: underline;
  }
  