.footer-container {
  background-color: #1a1a1a;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 50px 0;
}

.footer-info {
  max-width: 300px;
  text-align: left;
}

.footer-heading {
  font-size: 24px;
  margin-bottom: 20px;
}

.footer-text {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 30px;
}

.footer-link {
  background-color: #fff;
  border: none;
  border-radius: 5px;
  color: #1a1a1a;
  display: inline-block;
  font-size: 16px;
  margin-bottom: 20px;
  padding: 10px 20px;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
}

.footer-link:hover {
  background-color: #333;
  color: #fff;
}

.footer-contact {
  max-width: 300px;
  text-align: left;
}
.footer-contact img {
  height: 20px;
  width: 20px

}

.footer-social {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px;
}

.footer-social a {
  color: #fff;
  margin: 0 10px;
  transition: color 0.3s ease-in-out;
}

.footer-social a:hover {
  color: #f1c40f;
}

.footer-legal {
  margin-top: 30px;
  text-align: center;
}

.footer-legal p {
  font-size: 14px;
  margin: 5px 0;
}

.footer-legal a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.footer-legal a:hover {
  color: #f1c40f;
}


@media (max-width: 550px) {
  .footer-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 30px 0;
  }
  
  .footer-info,
  .footer-contact,
  .footer-legal {
    max-width: 100%;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .footer-heading {
    font-size: 22px;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .footer-text {
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 20px;
  }
  
  .footer-link {
    margin-bottom: 10px;
    font-size: 14px;
    padding: 8px 16px;
  }
}

@media (max-width: 550px) {
  .footer-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 30px 0;
  }
  
  .footer-info,
  .footer-contact,
  .footer-legal {
    max-width: 100%;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .footer-heading {
    font-size: 22px;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .footer-text {
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 20px;
  }
  
  .footer-link {
    margin-bottom: 10px;
    font-size: 14px;
    padding: 8px 16px;
  }
}
