.blog--containter {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .blog--h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .blog--user {
    margin-bottom: 10px;
  }
  
  .blog--links {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px 10px;
    margin-right: 10px;
  }
  
  .blog--method {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px 10px;
    margin-bottom: 10px;
  }
  
  .blog--method small {
    font-size: 16px;
  }
  
  .blog--content {
    margin-top: 20px;
  }
  
  .blog--content p {
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .blog--content img {
    max-width: 100%;
    margin-bottom: 20px;
  }
  
  .open-modal-button {
    margin-top: 20px;
  }
  
  .comments {
    margin-top: 20px;
  }
  
  .comment--content {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .comment--meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .comment--author {
    font-weight: bold;
    font-size: 16px;
  }
  
  .comment--date {
    font-size: 14px;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination button {
    background-color: #1E90FF;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .pagination button:hover {
    background-color: #0077FF;
  }
  
  .pagination .active {
    background-color: #0077FF;
  }
  
  .pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  