.about-container {
    margin: 50px auto;
    max-width: 600px;
    text-align: justify;
  }
  
  .about-container h1 {
    text-align: center;
  }
  
  .about-container p {
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .about-container b {
    font-weight: bold;
  }
  
  .about-container a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .about-container a:hover {
    text-decoration: underline;
  }
  