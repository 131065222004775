/* Default styles for form */
form {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

label {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

input,
textarea {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

.app__footer-cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.p-text {
  font-size: 1.2rem;
  line-height: 1.5;
  border: none;
  border-bottom: 2px solid #ccc;
  padding: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 400px;
  text-decoration: none;
}

.app__footer-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
}

.imgg {
  width: 30px;
  margin-right: 0.5rem;
}

.imggw {
  width: 50px;
  margin-right: 0.5rem;
  margin-left: 5px;
}

button[type="submit"] {
  background-color: #0077ff;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #0062cc;
}

.container-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f1f1f1;
}

.contactform {
  display: flex;
  flex-direction: column;
  width: 400px;
  background-color: #fff;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.contactform-label {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.contactform-input,
.contactform-textarea {
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  color: #333;
}

.contactform-textarea {
  height: 150px;
}

.contactform-submit {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
}

.contactform-submit:hover {
  background-color: #3e8e41;
}

/* Media queries for responsiveness */

@media only screen and (max-width: 768px) {
  form {
    width: 80%;
    margin: 0 auto;
  }

  .app__footer-cards {
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .app__footer-card {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .imggw {
    width: 40px;
    margin-left: 0.5rem;
  }

  .container-form {
    height: auto;
  }

  .contactform {
    width: 100%;
    margin: 0 auto;
  }
}
