.payment {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .payment__header {
    margin-bottom: 30px;
  }
  
  .payment__title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .payment__subtitle {
    font-size: 22px;
    margin: 0;
  }
  
  .payment__info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .payment__card {
    flex-basis: 60%;
  }
  
  .payment__card-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .payment__card-text {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .payment__card-form{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .payment__bank {
    flex-basis: 35%;
  }
  
  .payment__bank-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .payment__bank-text {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .payment__bank-details {
    margin-bottom: 20px;
  }
  
  .payment__bank-name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .payment__bank-info {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 5px;
  }
  
  .payment__bank-info span {
    font-weight: bold;
  }
  .number {
    font-weight: bold;
  }
  .payment__button {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #2196f3;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .payment__button:hover {
    background-color: #0d8bf0;
  }
  
  .payment__contact-card {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .payment__contact-card img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  .p-text {
    font-size: 16px;
    color: #333;
    text-decoration: none;
  }
  
  .span {
    font-weight: bold;
  }
  
  /* Responsive styles */
  @media only screen and (max-width: 600px) {
    .payment {
      padding: 20px;
    }
    
    .payment__card {
      flex-basis: 100%;
      margin-bottom: 20px;
    }
    
    .payment__bank {
      flex-basis: 100%;
    }
    
    .payment__info {
      flex-wrap: wrap;
    }
    
    .payment__button {
      margin-top: 20px;
    }
  }
  