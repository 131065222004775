* {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin-top: 20px;
}

.page-item {
  margin: 0 5px;
}

.page-item .page-link {
  display: block;
  padding: 8px 12px;
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  color: #333;
  text-decoration: none;
  transition: background-color 0.3s;
}

.page-item .page-link:hover {
  background-color: #ddd;
}

.page-item.active .page-link {
  background-color: #333;
  color: #fff;
}

.bloglink{
  text-decoration: none;
  color:white;
  padding:10px;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-form {
  max-width: 600px; /* set the maximum width to 600px or adjust as desired */
  margin: 0 auto; /* center the form horizontally */
}

.avatar{
  width: 30px;
  height: 30px;
  object-fit: cover;
  transform: translateY(-3px);
  border-radius: 50%;
}
/* .app-container {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
  display: grid;
  
} */

.navbar-nav .nav-link {
  color: green;
}

.navbar-nav .active .nav-link {
  color: green;
  
}

.topbanner {
  display: flex;
  background-color: black;
  padding: 10px;
  justify-content: space-between;
}

.topbanner p {
  color: white;
  margin: 0;
}
.topbanner img {
  height:30px;
  width: 30px
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.admin-pagination li {
  display: inline-block;
  margin-right: 5px;
}

.admin-pagination li button {
  background-color: #f2f2f2;
  border: none;
  color: #333;
  padding: 8px 12px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.admin-pagination li button:hover {
  background-color: #ddd;
}

.admin-pagination li.active button {
  background-color: #007bff;
  color: #fff;
}



@media (max-width: 600px) {
  /* Adjust styles for screens smaller than 768px */
  .topbanner {
    /* flex-direction: column;
    align-items: center; */
    padding: 5px;
    font-size: smaller;
  }
  
  .topbanner p {
    margin-bottom: 5px;
  }
}

@media (max-width: 400px) {
  /* Adjust styles for screens smaller than 768px */
  .topbanner {
    /* flex-direction: column;
    align-items: center; */
    padding: 5px;
    font-size: 12px;
  }
  
  .topbanner p {
    margin-bottom: 5px;
  }
}

@media (max-width: 335px){
  .topbanner {
    display: none;
  }
}
/* ---------- Auth Page------------ */
@import url('./auth.css');

/* ---------- Loading Page------------ */
@import url('./loading.css');

/* ---------- Alert Page------------ */
@import url('./alert.css');

/* ---------- Profile Page------------ */
@import url('./profile.css');

/* ---------- Category Page------------ */
@import url('./category.css');

/* ---------- Home Page------------ */
@import url('./home.css');

/* ---------- Blogs Category Page------------ */
@import url('./blogs_category.css');

/* ---------- Comments ------------ */
@import url('./comments.css');
