.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2); /* a subtle shadow effect */
    max-width: 500px;
    width: 100%;
  }
  
  .modal-form {
    display: flex;
    flex-direction: column;
  }
  
  .modal-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-bottom: 10px;
  }
  
  .modal-input:focus {
    outline: none;
    border-color: #0077cc; /* a blue color */
  }
  
  select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-bottom: 10px;
    background-color: white;
    appearance: none; /* removes the default appearance */
    background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M14.712.584L7.953 7.343a.996.996 0 0 1-1.414 0L1.288.584A1 1 0 0 1 2.701-.83L8 5.678l5.298-6.508a1 1 0 0 1 1.414 1.414z" fill="%23333"/></svg>'); /* adds a dropdown arrow */
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px 16px;
  }
  
  select:focus {
    outline: none;
    border-color: #0077cc; /* a blue color */
  }
  
  button[type="submit"] {
    padding: 10px 20px;
    background-color: #0077cc; /* a blue color */
    color: white;
    border: none;
    border-radius: 3px;
    margin-top: 10px;
    cursor: pointer;
  }
  
  button[type="submit"]:hover {
    background-color: #005ea8; /* a darker shade of blue */
  }
  
  .modal-footer-button {
    padding: 10px 20px;
    background-color: #ddd; /* a light gray color */
    color: #333;
    border: none;
    border-radius: 3px;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .modal-button-cancel {
    background-color: #c00; /* a red color */
    color: white;
    margin-top: 10px;
  }
  
  .modal-button-cancel:hover {
    background-color: #a00; /* a darker shade of red */
  }
  