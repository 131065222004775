.departments-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 16px;
}
.departments-container h1{
  font-size: 24px;
  font-weight: 600;
}
.title {
  font-size: 36px;
  margin-bottom: 32px;
  text-align: center;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list-item {
  font-size: 24px;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 4px;
  background-color: #f5f5f5;
  transition: background-color 0.3s ease-in-out;
}

.list-item:hover {
  background-color: #e0e0e0;
}

.article-title {
  font-size: 24px;
  margin-top: 32px;
  margin-bottom: 16px;
  
  padding: 8px;
}



.article-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.article-item {
  margin-bottom: 16px;
  cursor: pointer;
}

.list{
  padding:5px;
  font-size: 20px;
  cursor: pointer;
}

.list:hover{
  color: rgb(18, 10, 83);
}

.blog-box {
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 4px;
}


.blog-item {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 16px;
  cursor: pointer;
}

.blog-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;
}
.blog-title span{
  color: rgb(34, 1, 1);
}

.blog-metadata {
  font-size: 16px;
  color: #666;
}

.blog-metadata span {
  margin-right: 16px;
}

.blog-pages {
  font-weight: bold;
}

.blog-chapter {
  font-weight: bold;
}
.blog-chapter span {
  color: rgb(34, 1, 1);
}

.blog-price {
  font-weight: bold;
}
.blog-date span{
  font-weight: bold;
  color: rgb(34, 1, 1);
}

@media (max-width: 768px) {
  .blog-title {
    font-size: 20px;
  }

  .blog-metadata {
    font-size: 14px;
  }

  .blog-pages {
    font-size: 14px;
  }

  .blog-chapter {
    font-size: 14px;
  }

  .blog-price {
    font-size: 14px;
  }
}
