/* PayingUser.css */
.paying-user-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .paying-user-heading {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .paying-user-card {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .paying-user-info {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination-button {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .pagination-button.active {
    background-color: #ccc;
  }
  
  